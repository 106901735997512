import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { withQuoteWizard } from '../components/withQuoteWizard';

import { PublicSpacesPage } from '../imageURLs';

import SEO from '../components/SEO';

import Hero from '../components/Hero/Hero';
import TextBlock from '../components/TextBlock';
import CtaCard from '../components/CtaCard/CtaCard';
import CTAButton from '../components/CTAButton/CTAButton';
import CtaBlock from '../components/CtaBlock';
import ServiceIntroBlock from '../components/ServiceIntroBlock';
import CenterContent from '../components/CenterContent';

const styles = theme =>
  createStyles({
    root: {},
    checkmarksWrap: {
      backgroundColor: '#FFFFFF',
      [theme.breakpoints.up(960)]: {
        alignItems: 'center',
      },
    },
    cards: {
      backgroundColor: '#FFFFFF',
    },
  });

class ThePage extends React.Component {
  render() {
    const { children, classes } = this.props;

    return (
      <React.Fragment>
        <SEO
          title="Public Spaces Projects by Twin View Turf - Producer of Australia’s Best Lawns"
          keywords={[
            `Turf`,
            `Lawns`,
            `Best Lawns`,
            `Australia’s Best Lawns`,
            `lawn producer`,
          ]}
        />
        <Hero heroImage={PublicSpacesPage.hero} noQuote>
          <TextBlock text="Public Spaces" />
        </Hero>

        <Grid container item className={classes.cards}>
          <ServiceIntroBlock
            checklist={[
              'Turf patching',
              'Irrigation trenches',
              '360-degree project management',
              'trusted job will be handed above satisfactory manner',
              'on time and on budget, open communication',
            ]}
            statementTitle="SEQ's most trusted turf supplier for council"
            statementCopy="Twin View Turf are SEQ's most trusted, reliable turf specialist for completing quality public spaces."
          />
        </Grid>

        <Grid container item className={classes.cards}>
          <CenterContent style={{ maxWidth: 960 }}>
            <CtaCard
              image={PublicSpacesPage.southbank}
              header="Southbank Riverside Green Playground"
              text="Southbank’s Riverside Green Playground was refurbished in 2015 with a new kids playground and family-friendly green space."
              ctaHref="/public-spaces/southbank-riverside-green-playground"
              cardType="article"
              ctaText="Read more"
            />
            <CtaCard
              image={PublicSpacesPage.gabba}
              header="The Gabba"
              text="Adele's sellout concert in 2018 left the playing surface in need of a turf replacement – Twin View Turf completed the refurbishment with nothing other than TifTuf."
              ctaHref="/public-spaces/the-gabba-refurbishment"
              cardType="article"
              ctaText="Read more"
            />
          </CenterContent>
        </Grid>

        <CtaBlock title="Planning a project?">
          <CTAButton
            inverted
            ctaHref="/contact"
            ctaText="Contact Us"
          />
        </CtaBlock>

        {children}
      </React.Fragment>
    );
  }
}

export default withQuoteWizard(
  withStyles(styles, { withTheme: true })(ThePage)
);
